import { memo, useMemo } from 'react'
import styled from 'styled-components'
import StyledBox from '../moesia/components/StyledBox'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import Spacings from 'src/figma/tokens/Spacings'
import createKeyIndex from '../helpers/createKeyIndex'
import StyledLink from './StyledLink'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import SVGContainer from '../moesia/components/SVGContainer'
import TextContainer from '../moesia/components/TextContainer'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { REGULAR_TRANSITION } from 'src/moesia/Constants'
import { sendGAEvent } from 'src/helpers/GoogleAnalyticsHelper'
import getReachUsLinkOptions from '../constants/ReachUsLinkOptions'

const HomeReachUsSocialLinks = () => {
  const isMobile = useIsMobile()
  const reachUsOptions = useMemo(() => getReachUsLinkOptions(isMobile), [isMobile])

  return (
    <StyledBox gap={Spacings.medium}>
      <StyledBox gap={isMobile ? Spacings.large : Spacings.big} top bottom spacing={Spacings.big}>
        {!!reachUsOptions &&
          reachUsOptions.map(({ href, optionPlaceholder, googleAnalyticsEvent, icon }, index) => (
            <StyledLink
              openInNewTab
              key={createKeyIndex(index, 'home-reach-us-wrapper')}
              href={href}
              onClick={() => googleAnalyticsEvent && sendGAEvent(googleAnalyticsEvent)}
            >
              <StyledLinksWrapper direction="row" align="center" gap={Spacings.tiny}>
                <StyledSVGContainer SVG={icon} />
                <TextContainer textKey={optionPlaceholder} />
              </StyledLinksWrapper>
            </StyledLink>
          ))}
      </StyledBox>
    </StyledBox>
  )
}

export default memo(HomeReachUsSocialLinks)

const StyledLinksWrapper = styled(StyledBox)`
  transition: ${REGULAR_TRANSITION};
  color: ${FigmaColors.white};
  &:hover {
    svg {
      background-color: ${FigmaColors.white};
    }
    svg path {
      fill: ${FigmaColors.bhBlue};
    }
  }
`
const StyledSVGContainer = styled(SVGContainer)`
  svg {
    border-radius: ${BorderRadius.rounded};
  }
`
