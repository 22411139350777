import { memo, useState } from 'react'
import { TERVEL_NEWS_CARD_FLIP_CARD_PERSPECTIVE } from '../moesia/StrongHardCodedSizes'
import { ClientTypes } from '../interfaces/ClientTypes'
import ClientCardDesktop from './ClientCardDesktop'
import styled from 'styled-components'
import StyledBox from '../moesia/components/StyledBox'

type Props = {
  clientCard: ClientTypes
}

const AnimatedClientCardDesktop = ({ clientCard }: Props) => {
  const { clientLogo, clientTestimonyText, clientRepresentativeName, clientDescription, clientWebsite } = clientCard
  const [isHovered, setIsHovered] = useState(false)

  const handleHoverState = () => setIsHovered(!isHovered)

  return (
    <FlipCard onMouseEnter={handleHoverState} onMouseLeave={handleHoverState}>
      <FlipCardInner position="relative" $isHovered={isHovered}>
        <ClientCardDesktop clientLogo={clientLogo} centerText={clientTestimonyText} representativeName={clientRepresentativeName} />
        <ClientCardDesktop clientLogo={clientLogo} centerText={clientDescription} clientWebsite={clientWebsite} />
      </FlipCardInner>
    </FlipCard>
  )
}

export default memo(AnimatedClientCardDesktop)

const FlipCard = styled(StyledBox)`
  // Used for the 3D effect when flipping the card.
  perspective: ${TERVEL_NEWS_CARD_FLIP_CARD_PERSPECTIVE};
`

const FlipCardInner = styled(StyledBox)<{ $isHovered: boolean }>`
  transition: transform 0.8s;
  transform: ${({ $isHovered }) => $isHovered && 'rotateY(180deg)'};
  transform-style: preserve-3d;
`
