import { memo, useMemo } from 'react'
import StyledBox from '../moesia/components/StyledBox'
import Carousel from '@itseasy21/react-elastic-carousel'
import { clients } from '../interfaces/ClientsContent'
import { getShuffledArray } from 'src/helpers/Common'
import CarouselScrollArrowComponent from './CarouselScrollArrowComponent'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import {
  TERVEL_CAROUSEL_LEFT_RIGHT_PADDING_MOBILE,
  TERVEL_CAROUSEL_TOP_BOTTOM_PADDING_MOBILE,
  TERVEL_TWO_ITEMS_TO_SHOW,
  TERVEL_DEFAULT_ITEMS_TO_SHOW_MOBILE
} from 'src/moesia/WeakHardCodedSizes'
import ClientCard from './ClientCard'

const HomeClientCardsCarousel = (): JSX.Element => {
  const isMobile = useIsMobile()

  const shuffledClientCards = useMemo(() => getShuffledArray(clients), [])

  return (
    <StyledBox fullWidth align="center">
      <StyledBox fullWidth align="center" justify="center">
        <Carousel
          itemPadding={[TERVEL_CAROUSEL_TOP_BOTTOM_PADDING_MOBILE, TERVEL_CAROUSEL_LEFT_RIGHT_PADDING_MOBILE]}
          isRTL={false}
          itemsToShow={!isMobile ? TERVEL_TWO_ITEMS_TO_SHOW : TERVEL_DEFAULT_ITEMS_TO_SHOW_MOBILE}
          pagination={false}
          disableArrowsOnEnd
          renderArrow={CarouselScrollArrowComponent}
        >
          {!!shuffledClientCards && shuffledClientCards.map((clientCard, idx) => <ClientCard key={idx} clientCard={clientCard} />)}
        </Carousel>
      </StyledBox>
    </StyledBox>
  )
}

export default memo(HomeClientCardsCarousel)
