import React, { memo } from 'react'
import TervelLayout from 'src/components/TervelLayout'
import HomeLandingMobile from '../components/HomeLandingMobile'
import StyledBox from '../moesia/components/StyledBox'
import { Images } from '../figma/helpers/imageHelper'
import { Texts } from '../figma/helpers/TextRepository'
import styled from 'styled-components'
import HomeLandingDesktop from '../components/HomeLandingDesktop'
import HomeSliderComponent from '../components/HomeSliderComponent'
import HomeClientsComponent from '../components/HomeClientsComponent'
import HomeSloganComponent from '../components/HomeSloganComponent'
import HomeJoinUsComponent from '../components/HomeJoinUsComponent'
import HomeReachUsComponent from '../components/HomeReachUsComponent'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import NavigationMotion from '../components/NavigationMotion'
import TextContainer from 'src/moesia/components/TextContainer'
import SEO from 'src/components/SEO'
import routePaths from 'src/config/RoutePaths'

const HomeContainer: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <NavigationMotion>
      <TervelLayout>
        <SEO description={Texts.MetaDescriptionHomePage.characters} keyWords={Texts.MetaKeywordsHomePage.characters} route={routePaths.entry} />
        <StyledBox fullWidth>
          {/* 1st Header */}
          {isMobile && <HomeLandingMobile />}
          {!isMobile && <HomeLandingDesktop />}

          {/* 2nd Vision */}
          <VisionSloganBox>
            <TextContainer textKey={Texts.WeBelieveThat} />
            <HomeSloganComponent fullWidth imageKey={Images.sloganPhoto} />
            <TextContainer textKey={Texts['areTheBest.']} />
          </VisionSloganBox>

          {/* 3rd People */}
          <HomeSliderComponent />

          {/* 4th Clients */}
          <HomeClientsComponent />

          {/* 5th Join Us */}
          <HomeJoinUsComponent />

          {/* 6th Reach Us */}
          <HomeReachUsComponent />
        </StyledBox>
      </TervelLayout>
    </NavigationMotion>
  )
}

export default memo(HomeContainer)

const VisionSloganBox = styled(StyledBox)`
  text-align: center;
  color: ${FigmaColors.white};
`
