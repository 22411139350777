import { ClientTypes } from 'src/interfaces/ClientTypes'
import AnimatedClientCardDesktop from './AnimatedClientCardDesktop'
import ClientCardMobile from './ClientCardMobile'
import ClientCardTablet from './ClientCardTablet'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import useWindowSize from 'src/moesia/hooks/useWindowSize'
import MediaQueries from 'src/figma/tokens/MediaQueries'

type Props = {
  clientCard: ClientTypes
}

const ClientCard: React.FC<Props> = ({ clientCard }) => {
  const isMobile = useIsMobile()
  const windowWidth = useWindowSize().width
  const isTablet = !isMobile && windowWidth <= Number(MediaQueries.lg)
  const isDesktop = !isMobile && !isTablet

  return (
    <>
      {isMobile && <ClientCardMobile clientCard={clientCard} />}
      {isDesktop && <AnimatedClientCardDesktop clientCard={clientCard} />}
      {isTablet && <ClientCardTablet clientCard={clientCard} />}
    </>
  )
}

export default ClientCard
