import React, { memo } from 'react'
import StyledBox from 'src/figma/components/StyledBox'
import styled from 'styled-components'
import ImageContainer from 'src/moesia/components/ImageContainer'
import { Images } from 'src/figma/helpers/imageHelper'
import ZIndex from 'src/figma/tokens/ZIndex'
import HomeReachUsContent from './HomeReachUsContent'
import Spacings from '../figma/tokens/Spacings'
import useIsMobile from 'src/moesia/hooks/useIsMobile'

const HomeReachUsComponent: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <Wrapper fullWidth align={isMobile ? 'center' : 'flex-start'} top bottom spacing={isMobile ? Spacings.large : Spacings.max}>
      <ImageContainerWrapper fullWidth imageKey={isMobile ? Images.landPageYouCanMobile : Images.landPageYouCan} />
      <StyledBox left={Spacings.medium} right={isMobile ? Spacings.medium : Spacings.max}>
        <HomeReachUsContent />
      </StyledBox>
    </Wrapper>
  )
}

export default memo(HomeReachUsComponent)

const Wrapper = styled(StyledBox)`
  position: relative;
  height: 100%;
`

const ImageContainerWrapper = styled(ImageContainer)`
  img {
    position: absolute;
    z-index: ${ZIndex.negative};
    height: 100%;
    width: 100vw;
    object-fit: cover;
    top: -${Spacings.minimum};
    left: 0;
  }
`
