import React from 'react'
import Router from './Router'
import { HelmetProvider } from 'react-helmet-async'
import { Context, useAppState } from './hooks/useAppState'
import HelmetMaterialUIIcons from './helpers/HelmetMaterialUIIcons'
import useGA4Tracker from './hooks/useGA4Tracker'
import SomethingWentWrongComponent from './components/SomethingWentWrongComponent'
import * as Sentry from '@sentry/react'
import AppInitialization from './components/AppInitialization'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const App: React.FC = () => {
  const { state, setState } = useAppState()
  const { hasCookies } = state
  useGA4Tracker(hasCookies)

  return (
    <Sentry.ErrorBoundary fallback={<SomethingWentWrongComponent />}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <HelmetProvider>
          <Context.Provider value={{ state, setState }}>
            <HelmetMaterialUIIcons />
            <AppInitialization />
            <Router />
          </Context.Provider>
        </HelmetProvider>
      </LocalizationProvider>
    </Sentry.ErrorBoundary>
  )
}
export default App
