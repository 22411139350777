import { Helmet } from 'react-helmet-async'

type Props = {
  description: string
  keyWords?: string
  route: string
}

const SEO: React.FC<Props> = ({ description, keyWords, route }) => (
  <Helmet>
    <meta name="description" content={description} />
    {!!keyWords && <meta name="keywords" content={keyWords} />}
    <link rel="canonical" href={route} />
  </Helmet>
)

export default SEO
