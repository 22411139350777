import { GoogleAnalyticsEvents } from 'src/enums/GoogleAnalyticsEnums'
import { DEV_HARBOUR_LINKEDIN, HRISTO_STAMENOV_LINKEDIN, OFFICE_DEV_HARBOUR_EMAIL_HREF, VASSIL_NIKOLOV_LINKEDIN } from 'src/moesia/Constants'
import { Texts } from '../figma/helpers/TextRepository'
import { ReachUsOptionsType } from '../interfaces/ReachUsOptionsType'
import emailIconYouCanReacUs from '../figma/images/emailIconYouCanReacUs'
import linkedin from '../figma/images/linkedin'
import linkedinMobile from 'src/figma/images/linkedinMobile'
import emailIconYouCanReacUsMobile from 'src/figma/images/emailIconYouCanReacUsMobile'

const getReachUsLinkOptions = (isMobile: boolean): ReachUsOptionsType[] => [
  {
    href: OFFICE_DEV_HARBOUR_EMAIL_HREF,
    optionPlaceholder: Texts.YouCanReachUsTruEmail,
    googleAnalyticsEvent: GoogleAnalyticsEvents.CLICK_HOMEPAGE_EMAIL_OFFICE,
    icon: isMobile ? emailIconYouCanReacUsMobile : emailIconYouCanReacUs
  },
  {
    optionPlaceholder: Texts.YouCanReachUsTruLinkedIn,
    href: DEV_HARBOUR_LINKEDIN,
    googleAnalyticsEvent: GoogleAnalyticsEvents.CLICK_HOMEPAGE_DEVHARBOUR_LINKEDIN,
    icon: isMobile ? linkedinMobile : linkedin
  },
  {
    optionPlaceholder: Texts.YouCanReachUsTruLinkedInHristo,
    href: HRISTO_STAMENOV_LINKEDIN,
    icon: isMobile ? linkedinMobile : linkedin
  },
  {
    optionPlaceholder: Texts.YouCanReachUsTruLinkedInVassil,
    href: VASSIL_NIKOLOV_LINKEDIN,
    icon: isMobile ? linkedinMobile : linkedin
  }
]

export default getReachUsLinkOptions
