import routePaths from './config/RoutePaths'
import {
  BLOG,
  COMPANY,
  CONTACT_US,
  ERROR_404,
  GET_IN_TOUCH,
  HOME,
  JOIN_US,
  PORTFOLIO,
  SANDBOX,
  SOLO_BLOG,
  SOLUTIONS,
  UNDER_CONSTRUCTION
} from './constants/routeNames'
import loadable from '@loadable/component'
import DevHarbourLoader from './components/DevHarbourLoader'
import { WebsiteEntryType } from './enums/GoogleAnalyticsEnums'
import GATrackTraffic from './components/GATrackTraffic'

//make sure to double check if the page's component name is correct and is located in folder 'screens'
const loadScreen = (screenComponentPath: string) =>
  loadable(() => import(`./screens/${screenComponentPath}`), {
    fallback: <DevHarbourLoader />
  })

const HomeContainer = loadScreen('HomeContainer')
const CompanyContainer = loadScreen('CompanyContainer')
const SolutionsContainer = loadScreen('SolutionsContainer')
const PortfolioContainer = loadScreen('PortfolioContainer')
const BlogPostsContainer = loadScreen('BlogPostsContainer')
const BlogPostSoloViewContainer = loadScreen('BlogPostSoloViewContainer')
const ContactUsContainer = loadScreen('ContactUsContainer')
const Sandbox = loadScreen('SandboxContainer')
const Error404Component = loadScreen('Error404Component')
const UnderConstructionComponent = loadScreen('UnderConstructionComponent')

const RouterPublicRoutes = [
  { path: routePaths.entry, component: HomeContainer, title: HOME },
  { path: routePaths.company, component: CompanyContainer, title: COMPANY },
  { path: routePaths.solutions, component: SolutionsContainer, title: SOLUTIONS },
  { path: routePaths.portfolio, component: PortfolioContainer, title: PORTFOLIO },
  { path: routePaths.joinUs, component: PortfolioContainer, title: JOIN_US },
  { path: routePaths.blogPosts, component: BlogPostsContainer, title: BLOG },
  { path: routePaths.blogPost, component: BlogPostSoloViewContainer, title: SOLO_BLOG },
  { path: routePaths.contactUs, component: ContactUsContainer, title: CONTACT_US },
  { path: routePaths.getInTouch, component: PortfolioContainer, title: GET_IN_TOUCH },
  { path: routePaths.sandbox, component: Sandbox, title: SANDBOX },
  { path: routePaths.error404Page, component: Error404Component, title: ERROR_404 },
  { path: routePaths.underConstructionPage, component: UnderConstructionComponent, title: UNDER_CONSTRUCTION },
  { path: '*', component: Error404Component, title: ERROR_404 },
  { path: routePaths.redirectedFromJobsBg, component: () => <GATrackTraffic search={`typeEntry=${WebsiteEntryType.JOBS_BG}`} />, title: HOME },
  { path: routePaths.redirectedFromDevBg, component: () => <GATrackTraffic search={`typeEntry=${WebsiteEntryType.DEV_BG}`} />, title: HOME },
  { path: routePaths.redirectedFromRecommendation, component: () => <GATrackTraffic search={`typeEntry=${WebsiteEntryType.RECOMMENDATION}`} />, title: HOME }
]

export default RouterPublicRoutes
