import React, { memo, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import SVGContainer from '../moesia/components/SVGContainer'
import TextContainer from '../moesia/components/TextContainer'
import { Texts } from '../figma/helpers/TextRepository'
import Spacings from 'src/figma/tokens/Spacings'
import createKeyIndex from '../helpers/createKeyIndex'
import SectionWrapper from './SectionWrapper'
import StyledLink from './StyledLink'
import styled from 'styled-components'
import { FooterItems, getFooterItems } from 'src/interfaces/FooterContent'
import Copyright from '../helpers/FooterCopyrightHelper'
import { ColoredContainer, WhiteBox } from './StyleComponents'
import scrollToTop from '../moesia/helpers/ScrollToTopHelper'
import { Images } from '../figma/helpers/imageHelper'
import TemporaryImageContainer from './TemporaryImageContainer'
import routePaths from '../config/RoutePaths'
import LanguageBar from './LanguageBar'
import { MEDIA_BIG_SCREEN_MIN_WIDTH, TERVEL_DESKTOP_WHITE_BOX_HEIGHT, TERVEL_DESKTOP_WHITE_BOX_WIDTH } from '../moesia/StrongHardCodedSizes'
import devHarbourLogoWhite from '../figma/images/devHarbourLogoWhite'
import config from '../config'
import TermsPdf from '../assets/PrivacyPolicyTermsFiles/Dev-Harbor_PDF_terms_.pdf'
import PrivacyPdf from '../assets/PrivacyPolicyTermsFiles/Dev-Harbor_Policy.pdf'
import FooterSocialLinksComponent from './FooterSocialLinksComponent'
import StyledBox from '../moesia/components/StyledBox'
import FooterSubSection from './FooterSubSection'
import FigmaColors from '../figma/tokens/FigmaColors'
import circles from 'src/figma/images/circles'
import useLanguage from 'src/hooks/useLanguage'
import MediaQueries from 'src/figma/tokens/MediaQueries'
import ZIndex from 'src/figma/tokens/ZIndex'

const DesktopFooter: React.FC = () => {
  const year = new Date().getFullYear()
  const allRightsText = `${year} ${Texts.AllRightsReserved.characters}`
  const footerItems: FooterItems[] = useMemo(() => getFooterItems(), [])
  const navigate = useNavigate()
  const language = useLanguage()

  const handleClick = (url: string) => {
    navigate(url)
    scrollToTop()
  }

  return (
    <SectionWrapper>
      <ColoredContainer containerColor={FigmaColors.white} fullWidth align="center" top bottom spacing={Spacings.small}>
        <SVGContainer svgColor={FigmaColors.black} SVG={circles} />
      </ColoredContainer>
      <ColoredContainer containerColor={FigmaColors.blackBlue} fullWidth>
        <StyledBox position="relative" fullWidth>
          <LeftFooterImage imageKey={Images.footerLeft} />
        </StyledBox>
        <StyledBox direction="row" fullWidth gap={Spacings.max}>
          <LogoWrapper direction="column" align="center" top={Spacings.big} left right={Spacings.big} spacing={Spacings.max}>
            <StyledLink href={routePaths.entry}>
              <StyledSVGContainer SVG={devHarbourLogoWhite} />
            </StyledLink>
            <StyledBox top={Spacings.small}>{config.enabledLanguageBar && <LanguageBar buttonColor={FigmaColors.white} />}</StyledBox>
          </LogoWrapper>
          <FooterLinks
            fullWidth
            gap={Spacings.big}
            direction="row"
            flexWrap="wrap"
            justify="center"
            right={Spacings.large}
            top={Spacings.large}
            language={language}
          >
            {footerItems &&
              footerItems.map(({ listItemTextKey, url, subMenuSections }, itemIndex) => (
                <FooterItemsWrapper direction="column" flex={!itemIndex ? '0.2' : '0.4'} key={createKeyIndex(itemIndex, 'footer-drawer-routes')}>
                  <NavBox onClick={() => handleClick(url)} direction="row" align="center">
                    <WhiteBox width={TERVEL_DESKTOP_WHITE_BOX_WIDTH} height={TERVEL_DESKTOP_WHITE_BOX_HEIGHT} />
                    <StyledTextContainer textKey={listItemTextKey} />
                  </NavBox>
                  <StyledBox left>
                    <FooterSubSection subMenuSections={subMenuSections} />
                  </StyledBox>
                </FooterItemsWrapper>
              ))}
          </FooterLinks>
        </StyledBox>
        <StyledBox fullWidth align="center">
          <FooterSocialLinksComponent />
          <StyledBox bottom={Spacings.small}>
            <StyledLink openInNewTab href={PrivacyPdf}>
              <TextContainer textKey={Texts.AllRightsReserved} text={allRightsText} />
            </StyledLink>
          </StyledBox>
          <StyledLink openInNewTab href={TermsPdf}>
            <TextContainer textKey={Texts['ContactUsTerms&Conditions']} />
          </StyledLink>
          <StyledCopyrightBox top={Spacings.small} bottom={Spacings.large}>
            {Copyright(year)}
          </StyledCopyrightBox>
        </StyledBox>
      </ColoredContainer>
    </SectionWrapper>
  )
}

export default memo(DesktopFooter)

const LeftFooterImage = styled(TemporaryImageContainer)`
  position: absolute;
  left: 0;
`

const LogoWrapper = styled(StyledBox)`
  z-index: ${ZIndex.minimum};
  @media (min-width: ${MEDIA_BIG_SCREEN_MIN_WIDTH}px) {
    position: absolute;
  }
`

const FooterItemsWrapper = styled(StyledBox)`
  z-index: ${ZIndex.high};

  @media (min-width: ${MEDIA_BIG_SCREEN_MIN_WIDTH}px) {
    flex: none;
  }
`

const FooterLinks = styled(StyledBox)`
  @media (min-width: ${MediaQueries.md}px) and (max-width: ${MediaQueries.lg}px) {
    justify-content: flex-start !important;
  }
`

const NavBox = styled(StyledBox)`
  cursor: pointer;
`

const StyledTextContainer = styled(TextContainer)`
  span {
    font-size: 24px !important;
  }
  &:hover {
    && span {
      color: ${FigmaColors.green} !important;
    }
  }
`

const StyledCopyrightBox = styled(StyledBox)`
  color: ${FigmaColors.white};
  font-size: 22px;
`

const StyledSVGContainer = styled(SVGContainer)`
  svg {
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
`
