import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { Texts } from '../figma/helpers/TextRepository'
import Spacings from '../figma/tokens/Spacings'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import HomeReachUsSocialLinks from './HomeReachUsSocialLinks'

const HomeReachUsContent = () => {
  const isMobile = useIsMobile()

  return (
    <StyledBox align="center" left={!isMobile && Spacings.large} right={!isMobile && Spacings.max}>
      <StyledBox alignText="center">
        <TextContainer textKey={isMobile ? Texts.YouCanReachUsMobile : Texts.YouCanReachUsTruOne} />
        {!isMobile && <TextContainer textKey={Texts.YouCanReachUsTruTwo} />}
      </StyledBox>
      <HomeReachUsSocialLinks />
      <TextContainer textKey={Texts.YouCanReachUsTruOtherMethods} />
    </StyledBox>
  )
}

export default HomeReachUsContent
