import { memo, useEffect } from 'react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

//using this component so we don't have to extend the dayjs at every place we use it but extend it once for the whole app instead
//this is only an example with dayjs but if we need something which we have to do in many places
//as this extend of dayjs, we can do it here, in this component and not in every component in which we need the concrete thing

const AppInitialization = () => {
  useEffect(() => {
    dayjs.extend(advancedFormat)
  }, [])

  return null
}

export default memo(AppInitialization)
