import React, { memo, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import SVGContainer from '../moesia/components/SVGContainer'
import TextContainer from '../moesia/components/TextContainer'
import { Texts } from '../figma/helpers/TextRepository'
import Spacings from 'src/figma/tokens/Spacings'
import createKeyIndex from '../helpers/createKeyIndex'
import SectionWrapper from './SectionWrapper'
import StyledLink from './StyledLink'
import styled from 'styled-components'
import StyledBox from '../moesia/components/StyledBox'
import { FooterItems, getFooterItems } from '../interfaces/FooterContent'
import Copyright from '../helpers/FooterCopyrightHelper'
import { ColoredContainer, WhiteBox } from './StyleComponents'
import scrollToTop from '../moesia/helpers/ScrollToTopHelper'
import { Images } from '../figma/helpers/imageHelper'
import TemporaryImageContainer from './TemporaryImageContainer'
import devHarbourLogoWhite from '../figma/images/devHarbourLogoWhite'
import routePaths from '../config/RoutePaths'
import LanguageBar from './LanguageBar'
import { TERVEL_MOBILE_WHITE_BOX_HEIGHT, TERVEL_MOBILE_WHITE_BOX_WIDTH } from '../moesia/StrongHardCodedSizes'
import config from '../config'
import TermsPdf from '../assets/PrivacyPolicyTermsFiles/Dev-Harbor_PDF_terms_.pdf'
import PrivacyPdf from '../assets/PrivacyPolicyTermsFiles/Dev-Harbor_Policy.pdf'
import FooterSocialLinksComponent from './FooterSocialLinksComponent'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import circles from 'src/figma/images/circles'
import FooterSubSection from './FooterSubSection'

const MobileFooter: React.FC = () => {
  const year = new Date().getFullYear()
  const footerItems: FooterItems[] = useMemo(() => getFooterItems(), [])
  const navigate = useNavigate()

  const handleClick = (url: string) => {
    navigate(url)
    scrollToTop()
  }

  return (
    <SectionWrapper>
      <ColoredContainer containerColor={FigmaColors.white} fullWidth align="center" top bottom spacing={Spacings.small}>
        <SVGContainer svgColor={FigmaColors.black} SVG={circles} />
      </ColoredContainer>
      <ColoredContainer containerColor={FigmaColors.blackBlue} fullWidth align="center" spacing={Spacings.large}>
        <StyledBox fullWidth direction="row" justify="flex-end" position="relative">
          <StyledBox fullWidth position="absolute">
            <LeftFooterImage imageKey={Images.mobileFooterImage} />
          </StyledBox>
          <StyledBox fullWidth direction="row" justify="flex-end" top={Spacings.small}>
            <StyledBox direction="column" align="center" justify="center">
              <StyledLink href={routePaths.entry} left={Spacings.large} right={Spacings.big}>
                <SVGContainer SVG={devHarbourLogoWhite} />
              </StyledLink>
              <StyledBox top={Spacings.small}>{config.enabledLanguageBar && <LanguageBar buttonColor={FigmaColors.white} />}</StyledBox>
            </StyledBox>
          </StyledBox>
        </StyledBox>
        <StyledBox top={Spacings.large} justify="space-around">
          {footerItems &&
            footerItems.map(({ listItemTextKey, url, subMenuSections }, itemIndex) => (
              <StyledBox key={createKeyIndex(itemIndex, 'footer-drawer-routes')} className="footer-item" left right>
                <NavBox justify="center" align="center" onClick={() => handleClick(url)} direction="row" top={Spacings.medium}>
                  <WhiteBox width={TERVEL_MOBILE_WHITE_BOX_WIDTH} height={TERVEL_MOBILE_WHITE_BOX_HEIGHT} />
                  <StyledTextContainer textKey={listItemTextKey} />
                </NavBox>
                <StyledBox left={Spacings.min}>
                  <FooterSubSection subMenuSections={subMenuSections} />
                </StyledBox>
              </StyledBox>
            ))}
        </StyledBox>
        <FooterSocialLinksComponent />
        <StyledBox>
          <StyledLink openInNewTab href={PrivacyPdf}>
            <TextContainer textKey={Texts.AllRightsReserved} />
          </StyledLink>
        </StyledBox>
        <StyledLink openInNewTab href={TermsPdf}>
          <TextContainer textKey={Texts['ContactUsTerms&Conditions']} />
        </StyledLink>
        <StyledCopyrightBox bottom spacing={Spacings.small}>
          {Copyright(year)}
        </StyledCopyrightBox>
      </ColoredContainer>
    </SectionWrapper>
  )
}

export default memo(MobileFooter)

const LeftFooterImage = styled(TemporaryImageContainer)`
  position: absolute;
  left: 0;
`

const NavBox = styled(StyledBox)`
  cursor: pointer;
`

const StyledTextContainer = styled(TextContainer)`
  &:hover {
    && span {
      color: ${FigmaColors.green} !important;
    }
  }
`

const StyledCopyrightBox = styled(StyledBox)`
  color: ${FigmaColors.white};
  p {
    font-size: ${Spacings.tiny};
  }
`
