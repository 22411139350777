import React from 'react'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import { Variant } from 'src/moesia/components/SubmitButton'
import TextContainer from 'src/moesia/components/TextContainer'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import styled from 'styled-components'
import { REGULAR_TRANSFORM, REGULAR_TRANSITION } from '../Constants'
import { getSubmitButtonActiveStyle, getSubmitButtonDisabledStyle, getSubmitButtonHoverAndFocusStyle } from './SubmitButtonStatesStyles'

type Props = {
  textKey: TextType
  text?: string
  variant?: Variant
  backgroundColor?: string
  disabled?: boolean
}

export const DefaultButton: React.FC<Props> = ({ textKey, text, backgroundColor, variant, disabled }) => (
  <StyledButton
    backgroundColor={backgroundColor}
    variant={variant}
    disabled={disabled}
    left={Spacings.tiny}
    right={Spacings.tiny}
    top={Spacings.min}
    bottom={Spacings.min}
    align="center"
    justify="center"
  >
    <TextContainer textKey={textKey} text={text} />
  </StyledButton>
)

export const PrimaryButton: React.FC<Props> = ({ textKey, text, disabled }) => (
  <DefaultButton textKey={textKey} text={text} backgroundColor={FigmaColors.bhBlue} variant="primary" disabled={disabled} />
)

export const SecondaryButton: React.FC<Props> = ({ textKey, text, disabled }) => (
  <DefaultButton textKey={textKey} text={text} backgroundColor={FigmaColors.secondaryButtonColor} variant="secondary" disabled={disabled} />
)

export const TertiaryButton: React.FC<Props> = ({ textKey, text, disabled }) => (
  <DefaultButton textKey={textKey} text={text} backgroundColor="transparent" variant="tertiary" disabled={disabled} />
)

const StyledButton = styled(StyledBox)<{ backgroundColor?: string; variant: Variant; disabled: boolean }>`
  box-shadow: ${({ variant }) => (variant === 'tertiary' ? 'none' : `${Shadows.baseHeavy}`)};
  border-radius: ${BorderRadius.soft};
  background-color: ${({ backgroundColor }) => !!backgroundColor && backgroundColor};
  transition: ${REGULAR_TRANSITION};
  &:hover {
    transform: ${REGULAR_TRANSFORM};
  }
  span {
    color: ${({ disabled }) => disabled && FigmaColors.grayDark} !important;
  }
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    ${({ variant, disabled }) => !!variant && !disabled && getSubmitButtonHoverAndFocusStyle(variant)}
  }
  &:focus {
    ${({ variant, disabled }) => !!variant && !disabled && getSubmitButtonHoverAndFocusStyle(variant)}
  }
  &:active {
    ${({ variant, disabled }) => !!variant && !disabled && getSubmitButtonActiveStyle(variant)}
  }

  ${({ variant, disabled }) => disabled && !!variant && getSubmitButtonDisabledStyle(variant)}
`
